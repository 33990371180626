<template>
  <div style="width: 100%; height: 300px">
    <div class="mo-wang-editor" ref="editor"></div>
  </div>
</template>

<script>
import wangEdit from "wangeditor";
// // api
import API from "@/api/modules/issue.js";
export default {
  name: "editor",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: { required: true },
  },
  data() {
    return {
      imgnum: 0, //有几张型图片
      h: "",
      url: "",
      placeholder: "请文明用语…",
      height: 257,
      editor: null,
      //配置富文本编辑器的菜单栏
      menu: [
        "undo", // 撤销
        "redo", // 重复
        // "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "fontName", // 字体
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "indent", // 缩进
        "lineHeight", // 行高
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "link", // 插入链接
        "list", // 列表
        // "todo", // 待办
        "justify", // 对齐方式
        // "quote", // 引用
        "emoticon", //表情包
        "image", // 插入图片
        // "video", // 视频
        // "table", // 表格
        // "code", // 插入代码
        "splitLine", // 分割线
      ],
    };
  },
  watch: {
    value(val) {
      // 如果值不相等再进行赋值，避免多次赋值造成闪烁
      if (this.editor && val !== this.editor.txt.html()) {
        this.editor.txt.html(val);
      }
    },
  },
  methods: {
    initEditor() {
      let url = this.url;
      const editor = new wangEdit(this.$refs.editor);
      editor.config.placeholder = this.placeholder;
      editor.config.height = this.height;
      editor.config.menus = this.menu;
      //实现上传视频
      editor.config.customUploadVideo = async function (files, insertVideoFn) {
        let fd = new FormData();
        fd.append("file", files[0]);
        let res = await API.upload(fd).then((res) => {
          if (res.code == 1) {
            insertVideoFn(res.data.fullurl);
          }
        });
      };

      // 限制类型
      // editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
      editor.config.uploadImgMaxLength = 1; // 一次最多上传 5 个图片

      //监听数据变化
      editor.config.onchange = this.handleContentChange;
      //上传图片
      editor.config.customUploadImg = async function (files, insertImgFn) {
        let fd = new FormData();
        fd.append("file", files[0]);
        let res = await API.upload(fd);
        if (res.code == 1) {
          //  res.data[0].url;
          insertImgFn(res.data.fullurl);
          // console.log(insertImgFn(this.url + res.data[0].url),'地址');
        } else {
          // console.log(res.msg, "失败??");
        }
      };

      // 创建编辑器
      editor.create();
      // 赋予初始值
      editor.txt.html(this.value);

      this.editor = editor;
    },
    // 重新赋予html
    huodeGTML(html) {
      this.editor.txt.html(html);
    },
    // 外部插入图片(只允许插入一张)
    charutu(url) {
      // 获取图片地址
      var di = url;
      // 往富文本里插入图片
      this.editor.cmd.do(
        "insertHtml",
        '<img src="' + di + '" style="max-width:100%;"/>'
      );
      // 获取富文本里的所有图片
      let a = this.GetTheHTML();
      // 判断富文本里是否存在图片
      let b = a.search(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, "") >= 0;
      // b为fasle不存在
      if (b == false) {
        // 不在执行
        return;
      } else {
        // 如果存在图片则用正则匹配到图片,然后将它替换为新的图片
        let c = a.replace(
          /<img [^>]*src=['"]([^'"]+)[^>]*>/g,
          `<img src="${di}" style="max-width:100%;"/>`
        );
        // 重新赋予富文本html
        this.huodeGTML(c);
      }
    },
    handleContentChange(newHtml) {
      this.h = newHtml;
      // console.log(this.h, '获得');
      this.$emit("chang", newHtml);
      // this.$parent.chang(newHtml)
    },
    // 获取html
    GetTheHTML() {
      return this.h;
    },
    /**
     * resultFiles 是 input 中选中的文件列表
     * insertImgFn 是获取图片 url 后，插入到编辑器的方法
     */
    async handleUploadImage(resultFiles, insertImgFn) {
      const imgUrl = await this.getObjectURL(resultFiles[0]);
      // 上传图片，返回结果，将图片插入到编辑器中
      insertImgFn(imgUrl);
    },

    async handleUploadVideo(resultFiles, insertVideoFn) {
      const videoUrl = await this.getObjectURL(resultFiles[0]);
      // 上传视频，返回结果，将视频插入到编辑器中
      insertVideoFn(videoUrl);
    },

    /**
     * 将file对象或blob对象转url
     * ref: https://www.cnblogs.com/linxue/p/9469715.html
     */
    getObjectURL(obj) {
      var url = null;
      if (window.createObjectURL != undefined) {
        url = window.createObjectURL(obj);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(obj);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(obj);
      }
      return url;
    },
  },
  mounted() {
    let baseUrl = process.env.VUE_APP_BASE2_API;
    this.url = baseUrl;
    this.initEditor();
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy();
    this.editor = null;
  },
};
</script>

<style lang="scss" scoped>
.mo-wang-editor {
  // 工具栏icon
  .w-e-toolbar .w-e-menu {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }

  // 工具栏提示文字
  .w-e-menu-tooltip {
    font-size: 12px;
  }

  // 工具栏边框
  .w-e-toolbar {
    border: 1px solid #dcdfe6 !important;
    border-bottom: none !important;
  }

  // 编辑区域边框
  .w-e-text-container {
    border: 1px solid #dcdfe6 !important;
  }

  // 默认提示文字
  .w-e-text-container .placeholder {
    font-size: 12px;
    color: #c1c5cd;
  }

  // 上传按钮
  .w-e-menu .w-e-panel-container .w-e-up-img-container .w-e-up-btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    font-size: 24px;
  }
}

:deep(.w-e-text-container) {
  min-height: auto !important;
  z-index: 30 !important;
}

// /deep/ p{
//   span{
//     display: inline-block !important;
//   }
// }
:deep(.w-e-toolbar) {
  z-index: 100 !important;
}
font[size="4"] {
  font-size: 16px;
}
</style>
