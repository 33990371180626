<!-- 举报弹框 -->
<template>
  <div class="_taik">
    <div class="fontOne mb9">投诉 {{ juname }}</div>
    <div class="flex space-between mb12">
      <!-- 广告垃圾 -->
      <div class="flex align-items cursor_pointer flex1" @click="boxcheck(0)">
        <div class="frame">
          <div class="samllFrame" v-show="boxcheckNum == 0"></div>
        </div>
        <div class="fontTwo">{{ "广告垃圾" }}</div>
      </div>
      <!-- 违规内容 -->
      <div class="flex align-items cursor_pointer flex1" @click="boxcheck(1)">
        <div class="frame">
          <div class="samllFrame" v-show="boxcheckNum == 1"></div>
        </div>
        <div class="fontTwo">{{ "违规内容" }}</div>
      </div>
      <!-- 灌水/重复发帖 -->
      <div class="flex align-items cursor_pointer flex1" @click="boxcheck(2)">
        <div class="frame">
          <div class="samllFrame" v-show="boxcheckNum == 2"></div>
        </div>
        <div class="fontTwo">{{ "灌水/重复发帖" }}</div>
      </div>
    </div>
    <div class="flex space-between mb12">
      <!-- 谩骂侮辱 -->
      <div class="flex align-items cursor_pointer flex1" @click="boxcheck(3)">
        <div class="frame">
          <div class="samllFrame" v-show="boxcheckNum == 3"></div>
        </div>
        <div class="fontTwo">{{ "谩骂侮辱" }}</div>
      </div>
      <!-- 攻击引战 -->
      <div class="flex align-items cursor_pointer flex1" @click="boxcheck(4)">
        <div class="frame">
          <div class="samllFrame" v-show="boxcheckNum == 4"></div>
        </div>
        <div class="fontTwo">{{ "误导舆论" }}</div>
      </div>
      <!-- 其他 -->
      <div class="flex align-items cursor_pointer flex1" @click="boxcheck(5)">
        <div class="frame">
          <div class="samllFrame" v-show="boxcheckNum == 5"></div>
        </div>
        <div class="fontTwo">{{ "其它" }}</div>
      </div>
    </div>
    <!-- 其他原因 -->
    <el-input
      v-model="input"
      placeholder="其他原因"
      v-if="boxcheckNum == 5"
    ></el-input>
    <!-- 提交按钮 -->
    <div class="flex btn mt12">
      <div class="attentionBtn mr12 fontFour" @click="inform">提交</div>
      <div class="attentionBtn fontFour" @click="close">取消</div>
    </div>
  </div>
</template>

<script>
import Bus from "@/assets/ligature.js";
import API from "@/api/modules/details.js";
export default {
  data() {
    return {
      boxcheckNum: 0, //选中的单选框
      input: "", //输入框内
      tietype: 0, // 被举报类型 //类型:0=帖子,1=评论
      juname: "", // 被举报人姓名
      juid: "", //别举报人id
      loading: null,
    };
  },
  created() {
    Bus.$on("sj", (id, type, nams) => {
      this.shuju(id, type, nams);
    });
  },
  methods: {
    shuju(a, b, c) {
      this.tietype = b;
      this.juname = c;
      this.juid = a;
    },
    close() {
      Bus.$emit("guanbis");
    },
    //单选框选择按钮
    boxcheck(num) {
      this.boxcheckNum = num;
    },
    // 举报方法
    async inform() {
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.articleRepot({
        cardid: this.juid,
        kind: this.tietype,
        port: 0,
        type: this.boxcheckNum,
        remark: this.input,
      }).then((res) => {
        if (res.code == 1) {
          this.loading.close();
          this.$message({
            message: "举报成功,请等待审核!",
            type: "success",
          });
        } else {
          this.loading.close();
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
      this.close();
    },
  },
};
</script>

<style scoped lang="scss">
._taik {
  width: 410px;
  height: 240px;
  border-radius: 17px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px 19px 10px 19px;
}

.frame {
  border: 1px solid #000;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 5px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.samllFrame {
  width: 70%;
  height: 70%;
  background: #1c69c6;
  border-radius: 50%;
}

:deep(.el-input__inner) {
  height: 30px;
}

.btn {
  justify-content: center;

  .attentionBtn {
    border: 1px solid #868d9d;
    border-radius: 17px;
    width: 72px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    cursor: pointer;

    &:hover {
      background: #1c69c6;
      color: #fff;
    }
  }
}

.mb9 {
  margin: 0 0 9px 0;
}

.mb12 {
  margin: 0 0 12px 0;
}

.mt12 {
  margin: 12px 0 0 0;
}

.mr12 {
  margin: 0 12px 0 0;
}

.fontOne {
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #27262f;
  letter-spacing: 0;
  font-weight: 500;
}

.fontTwo {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #7b8391;
  font-weight: 600;
}
</style>